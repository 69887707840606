.modify-term-root-container {
  .modify-term-card-container {
    // box shadow for card container
    box-shadow: 0px -4px 6px -4px rgba(0, 0, 0, 0.05),
      0px -10px 15px -3px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
      0px 4px 6px -4px rgba(0, 0, 0, 0.1);
  }

  .summary-container {
    span {
      // wordbreak for summary to avoid overflow
      word-break: auto-phrase;
    }

    .empty-field {
      // empty field color should be light grey
      span {
        color: #b8b8b8;
      }
    }
  }
}
