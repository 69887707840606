.create-customer-container {
  .pg-form-container,
  .customer-form-container {
    width: 60rem;
  }

  // design system card margin
  .Card-root-boundary {
    margin: 1.375rem 0;
    width: fit-content;
  }

  .consent-container {
    // the span in the consent container should not exceed the natural width in the page
    max-width: 67.5rem;
  }
  .vp-field-helper-text {
    // hide the consent checkbox error message as we just need to highlight in red
    display: none;
  }
}
