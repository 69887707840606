.checkbox-wrapper,
.checkbox-wrapper::before,
.checkbox-wrapper::after {
  box-sizing: border-box;
}

.checkbox-wrapper label {
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}

.checkbox-wrapper input {
  visibility: hidden;
  display: none;
}

/* Show the checked state SVG when input is checked */
.checkbox-wrapper input:checked:not(:disabled) ~ .checkbox-checked {
  display: block;
}

/* Show the unchecked state SVG when input is not checked */
.checkbox-wrapper input:not(:checked):not(:disabled) ~ .checkbox-unchecked {
  display: block;
}

/* Show the disabled state SVG when input is disabled */
.checkbox-wrapper input:disabled ~ .checkbox-disabled {
  display: block;
  pointer-events: none;
  cursor: none;
}

/* Default hidden state for all SVGs */
.checkbox-checked,
.checkbox-unchecked,
.checkbox-disabled {
  display: none;
}
