.pill-container {
  padding: 0.16rem 0.5rem 0.16rem 0.5rem;
  border-radius: 0.25rem;
  span {
    height: min-content;
    display: block;
  }
}
.rectangular-outlined-primary-pill {
  // TODO: muhammadAhmadEjaz get this from constants once that code is merged
  border: 1px solid #84abf0;
  background: #e4edff;
}

.rectangular-outlined-secondary-pill {
  // TODO: muhammadAhmadEjaz get this from constants once that code is merged
  border: 1px solid #c4c4c4;
  background: #eeeeee80;
}
