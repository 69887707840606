.truncated-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  pointer-events: none;
}

  .truncated-text:after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events:initial;
  }

.truncated-text:hover:after {
  cursor: pointer;
}

.truncated-tooltip {
  display: none;
  position: absolute;
  left: 0;
  background-color: #333333;
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.1), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  opacity: 0;
  transition: opacity 0.5s ease;
  transition-delay: 2s;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

.single-line-tooltip{
  top: -1.5rem;
  white-space: nowrap;
}

.multi-line-tooltip{
  top: -200%;
}

.truncated-text:hover + .truncated-tooltip {
  display: initial;
  opacity: 1;
}
