.more-info-root-container {
  width: 100%;

  .more-info-card-container {
    transition: width 0.3s ease, height 0.3s ease;

    // box shadow for card container
    box-shadow: 0px -4px 6px -4px rgba(0, 0, 0, 0.05),
      0px -10px 15px -3px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
      0px 4px 6px -4px rgba(0, 0, 0, 0.1);

    .expanded-accordion {
      svg {
        rotate: 270deg;
      }
    }

    .filled-auth-signer-fields {
      input {
        color: inherit;
      }
    }

    .more-info-text-area {
      label {
        font-size: 0.875rem;
        color: #121212;
      }
    }

    .tooltip-class {
      z-index: 9999;
    }

    .state-dropdown-container {
      .react-select__menu-list {
        max-height: 8.95rem;
      }
      // need a different line-height for this specific case, have to use important to override react-select's default
      .react-select__single-value {
        line-height: 1rem !important;
      }
    }

    .ein-form-field {
      input {
        color: inherit;
      }
    }

    .tax-alert-text {
      > div {
        padding-left: 2rem;
        padding-right: 2rem;
        background: #eee;
      }
    }

    .text-area-field-container {
      gap: 0.5rem;
    }
  }

  .back-btn,
  .submit-btn {
    border-radius: 0.25rem;
  }

  .btn-container {
    position: relative;
    .btn-overlay {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;
    }
  }
}
