.hubspot-need-info-modal {
  max-height: 80%;
  overflow: hidden; /* Ensure the container itself doesn't overflow */
  display: flex;
  flex-direction: column;

  > div {
    max-height: 100%; /* Ensure the child div respects the max-height of the container */
    overflow: auto; /* Allow scrolling within the child div if content exceeds its height */
    flex: 1; /* Ensure the child div expands to fill the container */
  }
}

.hubspot-modal-content > div {
  // this is used by react-modals content to self center
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hubspot-modal {
  // this is used by modal component to self center
  position: absolute;
  top: 50%;
  left: 50%;
  // added the important to override the default transform from other sources
  transform: translate(-50%, -50%) !important;
}

.hubspot-purchase-order-request-modal:has(.request-edit-modal) {
  display: flex;
  flex-direction: column;
  height: auto;
  > div {
    margin: 0;
    max-height: 20.75rem;
  }
}
