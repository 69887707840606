.not-compact {
  height: 2.25rem;
  border: 0.078rem solid #afafaf !important;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: white !important;
  &:hover {
    box-shadow: 0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.1),
      0rem 0rem 0.063rem 0rem rgba(0, 0, 0, 0.28);
  }
  input {
    height: 1.25rem;
    border: none !important;
    font-size: 0.875rem;
    &:hover {
      box-shadow: none !important;
    }
  }
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  .react-select__control {
    min-height: initial;
    max-width: 3.5rem;
  }
  .custom-select-container {
    min-width: 3.5rem !important;
    width: auto !important;
    .react-select--is-disabled{
      width: 100%;
    }
  }
  #prefix {
    top: 0.063rem;
    left: -0.25rem;
    font-size: 0.875rem;
    margin-left: -0.125rem;
  }
}
.error-border {
  border: 0.078rem solid #ea001e !important;
  input{
    color: #ea001e !important;
    border: none !important;
  }
}

.disabled-border {
  border: 0.078rem solid #eeeeee !important;
  input {
    // used important to override design system's default behavior which gives field white background on being disabled
    background-color: transparent !important; 
  }
}
.disabled-border-compact {
  border: 0.063rem solid #c4c4c4 !important;
  input {
    border: none !important;
  }
}

// reduced term input width to handle overlap with dropdown icon
.term-compact {
  #term {
    width: 90%;
  }
}
