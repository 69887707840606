@import '~react-datepicker/dist/react-datepicker.css';
@import 'react-tooltip/dist/react-tooltip.css';

.react-datepicker-wrapper {
  display: block;
}

.__react_component_tooltip {
  padding: 0 !important;
}
.progen-card:focus-within {
  border: 0 !important;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='text'] {
    padding-top: 0;
    padding-bottom: 0;
  }
  input[type='text']:focus {
    background: none;
    box-shadow: none;
  }
  .dashboard-single-card {
    @apply w-4/6 max-w-4xl;
  }
}

@layer components {
  .min-widget-height {
    min-height: 24.875rem;
  }

  .tab {
    @apply focus:outline-none cursor-pointer;
    @apply py-6 border-b-2 border-transparent vp-text-link text-vartana-black;

    &.default {
      @apply hover:border-vartana-blue-60;
    }

    &.selected {
      @apply hover:border-vartana-blue-60;
      @apply border-vartana-blue-60;
      @apply vp-text-link-bold;
    }

    &.disabled {
      @apply focus:outline-none;
      @apply pointer-events-none;
      @apply py-6;
      @apply text-sm;
      @apply text-vartana-gray-40;
      @apply border-0;
    }
  }

  .vp-body-bold {
    @apply text-sm;
    @apply font-semibold;
  }

  .vp-page-title {
    @apply text-2xl;
    @apply font-bold;
  }

  .vartana-p-small-bold {
    @apply text-xs;
    @apply font-semibold;
  }

  .vp-p-small {
    @apply font-normal;
    @apply text-xs;
    @apply leading-4;
  }

  .p-xsmall {
    @apply font-medium;
    @apply text-[0.625rem];
    @apply leading-3;
  }

  .vp-body {
    @apply text-sm;
  }

  .vp-display {
    @apply font-semibold;
    @apply text-center;
    @apply text-3xl;
  }

  .vp-text-link-bold-ul {
    @apply text-sm;
    @apply underline;
    text-underline-offset: 2px;
    @apply font-semibold;
    line-height: 1.3125rem;
  }

  .vp-text-link-bold {
    @apply text-sm;
    @apply font-semibold;
  }

  .vp-text-link-semibold {
    @apply text-sm;
    @apply font-semibold;
    font-weight: 400;
  }

  .vp-text-link-xxsm {
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 1rem;
  }

  .vp-text-link {
    @apply text-sm;
  }

  .vp-card-subtitle-bold {
    @apply not-italic;
    @apply font-bold;
  }

  .card-categories {
    @apply text-sm;
    @apply font-semibold;
    @apply leading-6;
  }

  .card-subtitle-bold {
    @apply text-base;
    @apply font-semibold;
  }

  .vp-card-title {
    @apply text-lg;
    @apply font-medium;
  }

  .vp-card-title-bold {
    @apply text-lg;
    @apply font-semibold;
  }

  .vp-btn-text {
    @apply text-sm;
    @apply font-semibold;
  }

  .page-title-small {
    @apply text-xl;
    @apply font-bold;
    @apply leading-7;
  }

  .vp-field-label {
    @apply text-xs;
  }

  .vp-field-text {
    @apply text-sm;
  }

  .vp-field-helper-text {
    @apply font-medium;
    @apply leading-4;
    font-size: 0.625rem;
  }

  .portal-body-bold {
    @apply text-sm;
    @apply font-semibold;
  }

  .indexed-step {
    @apply bg-vartana-gray-30;
    @apply rounded-full;
    @apply w-2.5;
    @apply h-2.5;
  }

  .need-invoice {
    @apply bg-vartana-dark-mustard;
    box-shadow: 0 0 0 4px rgb(215, 147, 4, 0.3);
    @apply rounded-full;
    @apply w-2.5;
    @apply h-2.5;
  }

  .complete {
    @apply bg-vartana-blue-40;
    box-shadow: 0 0 0 4px rgba(27, 109, 255, 0.15);
    @apply rounded-full;
    @apply w-2.5;
    @apply h-2.5;
  }

  .inactive {
    @apply bg-vartana-red-100;
    box-shadow: 0 0 0 4px rgba(240, 93, 93, 0.2);
    @apply rounded-full;
    @apply w-2.5;
    @apply h-2.5;
  }

  .finished {
    @apply bg-vartana-green;
    box-shadow: 0 0 0 4px rgba(31, 193, 152, 0.3);
    @apply rounded-full;
    @apply w-2.5;
    @apply h-2.5;
  }

  .pill {
    @apply px-4;
    @apply py-1;
    @apply inline-flex;
    @apply text-xs;
    @apply leading-5;
    @apply font-semibold;
    @apply rounded-full;
    @apply uppercase;
    @apply whitespace-nowrap;
  }

  .canceled {
    @apply text-vartana-gray-50;
    @apply bg-vartana-gray-30;
  }

  .danger {
    @apply text-vartana-dark-red;
    @apply bg-vartana-light-coral;
  }

  .success {
    @apply text-vartana-dark-green;
    @apply bg-vartana-light-green-02;
  }

  .primary {
    @apply text-vartana-blue-160;
    @apply bg-vartana-blue-20;
  }

  .secondary {
    @apply text-vartana-dark-purple;
    @apply bg-vartana-light-purple;
  }

  .warning {
    @apply text-vartana-dark-mustard;
    @apply bg-vartana-yellow;
  }
  .mustard {
    @apply text-vartana-mustard-160;
    @apply bg-vartana-yellow;
  }
  .pre-qualified {
    @apply text-vartana-blue-90;
    @apply bg-vartana-sea-green;
  }
  .vp-field-helper-text {
    font-size: 10px;
  }

  // New style classes from Figma Design System
  .heading-16-bold {
    @apply font-semibold;
  }

  .heading-18-bold {
    @apply text-lg font-semibold;
  }

  .heading-24 {
    @apply text-2xl font-bold;
  }

  .heading-32 {
    @apply text-xl md:text-3xl font-bold leading-10;
  }

  .paragraph-14 {
    @apply text-sm;
    line-height: 1.313rem;
  }

  .link-14 {
    @apply text-sm;
    line-height: 1.313rem;
  }

  .button-text-14 {
    @apply text-sm font-semibold;
    line-height: 1.313rem;
  }
}

@layer base {
  // h1 {
  //     @apply text-5xl;
  // }
  // h2 {
  //     @apply text-4xl;
  // }
  // h3 {
  //     @apply text-3xl;
  // }
  // h4 {
  //     @apply text-2xl;
  // }
  // h5 {
  //     @apply text-xl;
  // }
  // h6 {
  //     @apply text-lg;
  // }

  .lds-dual-ring {
    border-radius: 50%;
    // border: 3px solid #fff;
    border-color: #1b6dff transparent #1b6dff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
    @keyframes lds-dual-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .toast-msg-shadow {
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.35), 0 4px 16px rgba(0, 0, 0, 0.2);
  }
}

.modal {
  @apply fixed;
  @apply inset-0;
  @apply bg-vartana-gray-60/95;
}

.modal-transparent {
  @apply fixed;
  @apply inset-0;
  background-color: #333333cc;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: content-box;
}

// min and max width range for small forms and cards
.small-card-width-range {
  max-width: 48.75rem;
  min-width: 43rem;
}

.flag-icon {
  border-radius: 1px;
  width: 0.938rem;
  height: 0.625rem;
}

.zip-input-container {
  input {
    text-transform: uppercase;
  }
}

.payout-panel-padding {
  padding: 2rem 2.5rem !important;
}
