.subsidy-bold-text {
  font-weight: 700 !important;
}
.tcv-input{
  #prefix{
    color: black !important;
  }
}

.customer-name-field-container{
  .InputField-root {
    background-color: white;
  }
}
.react-select__single-value{
  height: 1rem;
  margin-top: 0.125rem;
}

.error-message-container > div {
  border-radius: 0.125rem;
  margin-bottom: 1rem ;
}

.file-upload-header-container{
  button {
    padding: 0.375rem 0.5rem;
  }
}