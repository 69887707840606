.pg-form-container {

  input[type='checkbox'] {
    height: 1.125rem;
    width: 1.125rem;
    margin-top: 0.219rem;

    .error-border {
      border: red;
    }
  }
  .vp-field-helper-text {
    display: none;
  }

  .disabled-btn {
    svg {
      path {
        stroke: #afafaf;
      }
      circle {
        stroke: #afafaf;
      }
    }
  }

  .first-guarantor {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

  .followup-guarantor {
    margin-top: 1.5rem;
    padding-top: 1rem;
    border-top: 1px solid #e2e2e2;
  }

  .form-toggle-label {
    padding: 0.5rem 1rem 0.5rem 0.625rem;
    border-radius: 2rem;
    width: fit-content;

    div {
      margin-bottom: 0px;
      label {
        color: #121212;
      }
    }
  }
  .vp-field-label {
    font-size: 0.875rem;
  }

  .form-toggle-disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
  
  .InputFieldFormat-root {
    &::placeholder {
      color: #AFAFAF;
    }
  } 
  
  .checkbox-label {
    cursor: pointer;
  }
}
