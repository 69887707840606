// Variables
// TODO: muhammadAhmadEjaz to replace these with imports from colors constant file when ocr is merged
$colors: (
  color-blue-120: #054bc7,
  color-blue-160: #21438b,
  color-blue-100: #1460e7,
  color-blue-30: #e4edff,
  color-blue-10: #fafbff,
  color-gray-110: #929292,
  color-gray-40: #e2e2e2,
  color-gray-30: #eeeeee,
  color-gray-30-50p: #eeeeee80,
  white: #ffffff,
  color-black-100: #121212,
);

// component specific spacings
$spacing: (
  xs: 0.03rem,
  sm: 0.12rem,
  md: 0.24rem,
  lg: 0.25rem,
  xl: 0.38rem,
  xxl: 0.5rem,
  xxxl: 0.75rem,
);

// Mixins (component specific)
@mixin button-state($bg-color, $text-color) {
  background: $bg-color;
  .select-value {
    color: $text-color;
    &.placeholder {
      color: $text-color;
    }
  }
  .select-arrow {
    fill: $text-color;
  }
}

@mixin flex-center {
  display: flex;
  align-items: center;
}

@mixin button-base {
  width: max-content;
  border-radius: 4px;
  @include flex-center;
  justify-content: space-between;
  gap: map-get($spacing, sm);
  cursor: pointer;
  transition: rotate 0.4s ease;
}

@mixin dropdown-shadow {
  box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px -10px 15px -3px rgba(0, 0, 0, 0.05), 0px -4px 6px -4px rgba(0, 0, 0, 0.05);
}

.interest-rate-select {
  width: 100%;
  max-width: max-content;
  @include flex-center;
  justify-content: flex-start;
  gap: map-get($spacing, md);

  .select-container {
    position: relative;
    width: 100%;
    max-width: max-content;
    &:focus {
      outline: none;
    }
  }

  .radio-container {
    @include flex-center;
    gap: map-get($spacing, lg);
    .radio-button-padding {
      padding: map-get($spacing, xs) map-get($spacing, xl);
    }
  }

  // Button States
  .select-button-default {
    @include button-state(
      map-get($colors, color-gray-30),
      map-get($colors, color-blue-120)
    );
  }

  .select-button-selected {
    @include button-state(
      map-get($colors, color-blue-30),
      map-get($colors, color-blue-100)
    );
  }

  .select-button-disabled {
    @include button-state(
      map-get($colors, color-gray-40),
      map-get($colors, color-gray-110)
    );
    pointer-events: none;
  }

  .select-button {
    @include button-base;

    &.active {
      outline: none;
      border-radius: map-get($spacing, lg);
      background: map-get($colors, color-blue-30);

      .select-value,
      .placeholder {
        color: map-get($colors, color-blue-160);
      }
      .select-arrow {
        fill: map-get($colors, color-blue-160);
      }
    }
    &:focus-visible {
      outline: none;
      border-radius: map-get($spacing, lg);
      border: 1px solid map-get($colors, color-blue-160);
      background: map-get($colors, color-blue-30);
      box-shadow: 0px 0px 0px 3px #adc7f7;

      .select-value,
      .placeholder {
        color: map-get($colors, color-blue-160);
      }
      .select-arrow {
        fill: map-get($colors, color-blue-160);
      }
    }
  }

  .select-button-unlocked {
    padding: map-get($spacing, xs) map-get($spacing, lg) map-get($spacing, xs)
      map-get($spacing, xl);
  }

  .select-button-locked {
    padding: map-get($spacing, xs) map-get($spacing, xl);
    pointer-events: none;
    background-color: map-get($colors, color-gray-30-50p);
    .select-value {
      color: map-get($colors, color-blue-120);
    }
  }

  .select-arrow {
    transition: transform 0.2s ease;
    &.open {
      transform: rotate(180deg);
    }
  }

  .select-value {
    font-size: 0.5625rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1rem;
  }

  .select-dropdown {
    padding: map-get($spacing, xl) 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: absolute;
    right: 0;
    background: map-get($colors, white);
    border-radius: map-get($spacing, xl);
    @include dropdown-shadow;
    z-index: 1000;
    max-height: 9rem;
    width: 5.3125rem;
    overflow-y: auto;
  }

  .select-dropdown-top {
    bottom: 100%;
    margin-bottom: 0.25rem;
  }

  .select-dropdown-bottom {
    top: 100%;
    margin-top: 0.25rem;
  }

  .select-option {
    padding: map-get($spacing, xl) map-get($spacing, xxxl);
    width: 100%;
    text-align: left;
    font-size: 0.75rem;
    color: map-get($colors, color-black-100);
    cursor: pointer;
    transition: background-color 0.2s ease;

    &.selected {
      background-color: map-get($colors, color-blue-30);
      color: map-get($colors, color-black-100);
    }
    &.focused {
      background-color: map-get($colors, color-gray-30);
    }
    &:hover {
      background-color: map-get($colors, color-gray-40);
    }
  }
}
