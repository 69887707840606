.filter-container-responsive {
  display: flex;
  gap: 1rem;
  justify-content: center;
  .filter-responsive {
    width: 100%;
    max-width: 522px;
  }
  @media screen and (max-width: 1440px) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
    .filter-responsive {
      width: 100%;
    }
  }
}
