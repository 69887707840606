.compact-currency-pill {
    @apply px-3;
    @apply py-1;
    @apply inline-flex;   
    @apply leading-5;
    @apply font-semibold;
    @apply uppercase;
    @apply whitespace-nowrap;
}

.compact-flag-text {
    line-height: 1rem;
    text-transform: uppercase;
}

.default-currency-pill {
    @apply px-4;
    @apply py-1;
    @apply inline-flex;   
    @apply leading-5;
    @apply font-semibold;
    @apply uppercase;
    @apply whitespace-nowrap;
}
  
.default-flag-text {
    line-height: 1.25rem !important;
    text-transform: uppercase;
}
  