.loader {
  width: 2rem;
  height: 2rem;
}

.spin {
  background: transparent;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  z-index: 0;
  -webkit-animation: spin2 1s linear infinite;
  animation: spin 1s linear infinite;

  &.white-spinner {
    .spin__blocker {
      background: #054bc7;
    }

    .spin__bottom-left {
      background: -webkit-linear-gradient(
        top left,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0.75) 100%
      );
      background: linear-gradient(
        to bottom right,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0.75) 100%
      );
    }

    .spin__bottom-right {
      background: -webkit-linear-gradient(
        bottom left,
        rgba(255, 255, 255, 0.75) 0%,
        rgba(255, 255, 255, 0.5) 100%
      );
      background: linear-gradient(
        to top right,
        rgba(255, 255, 255, 0.75) 0%,
        rgba(255, 255, 255, 0.5) 100%
      );
    }

    .spin__top-left {
      background: -webkit-linear-gradient(
        top left,
        rgba(255, 255, 255, 0.25) 0%,
        rgba(255, 255, 255, 0.5) 100%
      );
      background: linear-gradient(
        to bottom right,
        rgba(255, 255, 255, 0.25) 0%,
        rgba(255, 255, 255, 0.5) 100%
      );
    }

    .spin__top-right {
      background: -webkit-linear-gradient(
        top right,
        rgba(255, 255, 255, 0.25) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      background: linear-gradient(
        to bottom left,
        rgba(255, 255, 255, 0.25) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spin > div:not(.spin__blocker) {
  width: 50%;
  height: 50%;
  z-index: -1;
}

.spin__blocker {
  position: absolute;
  top: 0;
  left: 0;
  top: 3%;
  left: 7%;
  background: white;
  width: 90%;
  height: 90%;
  border-radius: 50%;
}

.spin__bottom-left {
  position: absolute;
  top: 50%;
  left: 0;
  background: -webkit-linear-gradient(
    top left,
    rgba(5, 75, 199, 1) 0%,
    rgba(5, 75, 199, 0.75) 100%
  );
  background: linear-gradient(
    to bottom right,
    rgba(5, 75, 199, 1) 0%,
    rgba(5, 75, 199, 0.75) 100%
  );
}

.spin__bottom-right {
  position: absolute;
  top: 50%;
  left: 50%;
  background: -webkit-linear-gradient(
    bottom left,
    rgba(5, 75, 199, 0.75) 0%,
    rgba(5, 75, 199, 0.5) 100%
  );
  background: linear-gradient(
    to top right,
    rgba(5, 75, 199, 0.75) 0%,
    rgba(5, 75, 199, 0.5) 100%
  );
}

.spin__top-left {
  position: absolute;
  top: 0;
  left: 50%;
  background: -webkit-linear-gradient(
    top left,
    rgba(5, 75, 199, 0.25) 0%,
    rgba(5, 75, 199, 0.5) 100%
  );
  background: linear-gradient(
    to bottom right,
    rgba(5, 75, 199, 0.25) 0%,
    rgba(5, 75, 199, 0.5) 100%
  );
}

.spin__top-right {
  position: absolute;
  top: 0;
  left: 0;
  background: -webkit-linear-gradient(
    top right,
    rgba(5, 75, 199, 0.25) 0%,
    rgba(5, 75, 199, 0) 100%
  );
  background: linear-gradient(
    to bottom left,
    rgba(5, 75, 199, 0.25) 0%,
    rgba(5, 75, 199, 0) 100%
  );
}

.spin__blocker {
  width: 80%;
  height: 80%;
  top: 10%;
  left: 10%;
}
