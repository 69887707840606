.common-table {
  table {
    // this table has space justified header and columns
    // and a fixed header
    // and a scrollable body
    width: 100%;
    thead {
      width: 100%;
      tr {
        width: 100%;
        min-height: 49px;
        th {
          width: 25%;
        }
      }
    }

    tbody {
      width: 100%;
      tr {
        width: 100%;
        min-height: 77px;
        td {
          width: 25%;
        }
      }
    }
  }
}