.content-container {
  .expanded-app-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 4.25rem;
    padding: 1.5rem 6rem;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px -4px 8px 2px rgba(0, 0, 0, 0.1);

    img {
      margin: 0;
      margin-left: auto;
    }
    &.hubspot-expanded-footer{
      position: initial;
    }
  }

  .checkout-link-form-card{
    width: 100%;
  }
}
